@use 'src/assets/styles/material-theme';
@import './assets/styles/variables';
@import './assets/styles/mixins';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    font-size: em(24px);
  }

  h2 {
    font-size: em(16px);
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .disabled-btn {
    opacity: 0.5;
  }

  form,
  .form {
    width: 514px;
    display: flex;
    flex-direction: column;
    padding: 0 1px;
  }

  .disabled {
    opacity: 0.6;
  }

  // material kontener
  .mat-drawer-container {
    background-color: $bon-white;

    &.light-blue-background-color {
      background-color: $light-blue-background;
    }
  }

  // Szerokości i wysokości
  $widths-percent: 50, 100;

  @each $width in $widths-percent {
    .w-#{$width} {
      width: calc(#{$width} * 1%);
    }
  }

  $widths-px: 125, 130, 140, 153, 158, 167, 185, 200, 242, 310;

  @each $width in $widths-px {
    .w-#{$width} {
      width: #{$width}px;
    }
  }

  .mw-475 {
    max-width: 475px;
  }

  .mw-514 {
    max-width: 514px;
  }

  // Fonty
  @for $i from 1 through 30 {
    .fs-#{$i} {
      font-size: #{$i}px;
    }
  }

  // Font color
  .text-red {
    color: $text-red;
  }

  .empty-data {
    background-color: #fff;
    font-weight: 700;
    text-align: center;
    padding: 65px 0;
    font-size: 18px;
  }

  // mat-mdc-dialog

  .mat-mdc-dialog-content {
    font-size: 16px;

    .mat-mdc-dialog-title {
      padding-left: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;

      &::before {
        height: 0;
      }
    }
  }

  .dialog-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .mat-mdc-dialog-actions {
    padding-right: 20px;
    padding-bottom: 20px;
    justify-content: end;

    .mat-mdc-raised-button,
    .mat-mdc-button-touch-target {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: normal;
      width: 232px;
      height: 43px;
      padding: 12px 20px 12px 20px;
      box-sizing: border-box;
      font-family: 'Roboto Bold', 'Roboto', sans-serif;
    }

    .main-dialog-close-button {
      border: 2px solid rgba(83, 80, 219, 0.99);
      color: rgba(83, 80, 219, 0.99);
      border-radius: 5px;
      margin-right: 1em;
      text-align: center;
      line-height: normal;
    }
  }

  // Menu settings

  .menu-current-user {
    &.mat-mdc-menu-panel.mat-mdc-menu-panel {
      border-top: 1px solid rgba(94, 116, 238, 0.25);
      border-bottom: 1px solid rgba(94, 116, 238, 0.25);
      box-shadow: none;
      border-radius: unset;
      min-width: calc(100%);
      position: absolute;
    }

    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  // Inputs

  // Focused
  .mdc-text-field--focused:not(.mdc-text-field--invalid) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $bon-blue-dark1 !important;
    }

    .mdc-floating-label {
      color: $bon-blue-dark1;
    }
  }

  // inactive
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $bon-blue-darker;
    }

    .mdc-floating-label--float-above {
      color: $bon-gray-darker;
    }

    &:hover {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $bon-blue-dark1 !important;
      }
    }
  }

  // error
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 2px;

      .mdc-text-field__input {
        color: $bon-gray-darker;
      }
    }
  }

  // readonly
  mat-label.readonly {
    color: $bon-gray-darker;
  }

  input[readonly] {
    color: $bon-gray-darker !important;

    .mdc-floating-label--float-above {
      color: $bon-gray-darker;
    }

    .mdc-text-field__input {
      color: $bon-gray-darker;
    }
  }

  // Radio buttons

  .mdc-radio__outer-circle {
    border-width: 1px;
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked {
    border-radius: 4px;
    border: 1px solid $btn-radio-blue;
    background-color: $light-blue-background;
    font-weight: bold;

    .mdc-radio__outer-circle {
      border-width: 2px;
    }
  }

  // Checkbox

  .mdc-checkbox__background {
    border-radius: 5px;
    border: 1px solid $btn-radio-blue;
  }

  .mat-mdc-checkbox-checked {
    font-weight: bold;

    .mdc-checkbox__background {
      background-color: $bon-blue !important;
    }
  }

  .mdc-checkbox__checkmark {
    width: 75%;
    top: 2px;
    left: 2px;
    color: $white !important;
  }

  .mat-checkbox-checked,
  .mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $bon-blue !important;
    }

    &.mat-checkbox-disabled {
      span.mat-checkbox-background > svg {
        background-color: $text-grey;
      }
    }
  }

  .form-checkbox {
    margin-bottom: 15px;
  }

  .mat-checkbox-inner-container {
    margin-top: 5px !important;
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  // mat-mdc-menu-panel
  .menu-table.mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 100%;
    min-width: min-content;

    .mat-mdc-menu-content {
      padding: 0;
    }

    .mat-mdc-menu-item {
      font-size: 12px;
      padding: 0.5em 3em 0.5em 1em;
      min-height: 1em;

      .mat-icon {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: $bon-blue-light;
      }
    }
  }
}

.menu-table.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 100%;
  min-width: min-content;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item {
    font-size: em(16px) !important;
    padding: em(20px) em(10px) em(46px) em(10px);
    min-height: 1em;

    .mat-icon {
      width: em(18px) !important;
      height: em(18px) !important;
      margin-right: 10px;
    }
  }
}

.icon {
  height: 25px;
}

.mat-mdc-dialog-content {
  font-size: 14px;
  padding-bottom: 26px;
  justify-content: end;

  h1 {
    padding-left: 0;

    .mat-mdc-dialog-title {
      font-weight: 500;
      font-size: 32px;
      margin-bottom: 16px;
    }
  }
}

.move-settlements-dialog .mat-mdc-dialog-container {
  box-sizing: border-box;
  width: 550px;

  .mat-mdc-dialog-actions button {
    margin: 0 10px !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
}

.row-with-icon {
  display: flex;
  gap: 22px;
  align-items: baseline;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Formularze

// input(48px) + gap(30px)
.mat-mdc-form-field {
  height: fit-content;
}

// error messages should push to keep it's space
.extra-space-for-double-error {
  .mat-mdc-form-field-error-wrapper {
    max-width: 280px;
    position: relative !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    content: none !important;
  }
}

.mat-mdc-form-field-error-wrapper {
  font-size: 12px;
  line-height: 18px;

  .mat-mdc-form-field-error {
    display: flex;
    align-items: center;
  }
}

.filters-inputs-errors-block .mat-mdc-form-field-subscript-wrapper {
  &::before {
    height: 0;
    content: unset;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 5px;
  }
}

// Filters section

.container-filters {
  display: grid;
  width: 100%;
  margin-top: em(32px);
  padding: em(4px) em(1px) em(36px) 0;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: em(16px);
  grid-row-gap: em(16px);
  overflow-y: visible;
  overflow-x: auto;

  .mat-mdc-form-field-error-wrapper {
    max-width: 280px;
    position: relative !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    content: none !important;
  }
}

// Buttons
.buttons-set {
  display: flex;

  column-gap: 16px;
  justify-content: flex-end;
}

.mdc-button {
  font-weight: 700;
  font-family: 'Roboto Bold', 'Roboto', sans-serif;
  font-size: 16px;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  box-shadow: none !important;

  &.mat-mdc-button-base {
    padding: 0 20px 0 20px;
    height: 46px;

    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button {
      &.mat-primary:not(:disabled) {
        background-color: $bon-blue;

        &:hover {
          background-color: $bon-blue-dark2;
        }
      }
    }

    &.bon-long-button {
      width: 180px;
    }

    &.bon-auto-width-button {
      width: auto;
    }
  }

  &:disabled {
    color: #fff !important;
    background-color: $bon-gray-light !important;
  }

  &:focus-visible {
    outline: #140c6f solid 2px;
    outline-offset: 2px;
  }
}

.mat-mdc-button-base:focus-visible {
  outline: #140c6f solid 2px;
}

.link-button-focus:focus-visible {
  outline: $stats-blue solid 1px;
  outline-offset: 2px;
}

//Stroked button

.mat-mdc-outlined-button:not(:disabled) {
  border-color: $bon-blue !important;
  border-width: 2px !important;

  &:hover {
    background-color: $bon-blue-light;
    border-color: $bon-blue;
  }
}

.mat-warn {
  color: #fff !important;

  &:not(:disabled):hover {
    background-color: $bon-red-dark;
  }
}

.mat-mdc-outlined-button {
  &.mat-mdc-button-base {
    border: 1px solid $color-error;
  }
}

.mdc-button--outlined {
  border: 1px solid $color-error;
}

.mat-mdc-outlined-button {
  border: 1px solid $color-error;
}

.icon-center-vertically {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;

  > .title {
    padding-right: 10px;
    padding-left: 10px;
    width: 180px;
    word-wrap: break-word;
    white-space: normal;
  }
}

.btn-spinner {
  position: relative;

  span.spinner-text {
    position: absolute;
    top: 0;
    left: 30px;
  }

  .mdc-button__label {
    width: 100%;
  }
}

// Snack bar
.snack-bar {
  color: $grey-dark;
  background-color: $bon-white;
  min-width: 412px;
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 5px;
  --mdc-snackbar-container-color: $bon-dark-white;
  --mdc-snackbar-supporting-text-color: $bon-dark-white;
  --mat-snack-bar-button-color: $grey-light-4;
  border-width: 2px;
  border-style: solid;
  border-left-width: 6px;

  .mdc-snackbar__surface {
    width: 100%;
    height: 100%;
  }

  a[role='button'] {
    .mat-icon {
      width: 14px;
      position: absolute;
      color: $grey-light-4;
      top: 8px;
      right: 17px;
    }
  }

  &-warning {
    border-color: $color-warning;

    .mat-icon {
      color: $color-warning;
    }
  }

  &-success {
    border-color: $color-success;

    .mat-icon {
      color: $color-success;
    }
  }

  &-error {
    border-color: $color-error;

    .mat-icon {
      color: $color-error;
    }
  }

  &-info {
    border-color: $color-info;

    .mat-icon {
      color: $color-info;
    }
  }
}

// Tabele
.mat-mdc-table {
  font-size: em(14px);
  border-collapse: separate;

  .mdc-data-table__row {
    height: 60px;
  }
}

.mdc-data-table__header-row {
  .mdc-data-table__header-cell {
    border-bottom-color: $bon-blue-dark3;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
}

.mdc-data-table__row {
  .mdc-data-table__cell {
    border-bottom-color: rgba(128, 128, 128, 0.99);
  }
}

.mdc-data-table__row:last-child > .mdc-data-table__cell {
  border-bottom-color: rgba(128, 128, 128, 0.99) !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.mat-mdc-table .mat-mdc-row:hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: $bon-blue-light !important;
}

.table-action-button-color {
  color: #2324b2;
}

.mat-mdc-icon-button[disabled] .table-action-button-color {
  opacity: 0.38;
}

.mat-mdc-header-cell {
  font-weight: bold !important;
}

// paginator

.bdn-link {
  color: $bon-blue;
  text-decoration: underline;

  &::after {
    content: '';
    display: inline-flex;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-image: url('./assets/svg/icon__Link-blue.svg');
  }

  &:hover {
    font-weight: bold;
  }
}

.bdn-link--internal {
  color: $bon-blue;
  text-decoration: underline;

  &:hover {
    font-weight: bold;
  }
}

.mat-mdc-paginator-container {
  font-size: em(14px);
}

.mat-mdc-paginator {
  background-color: inherit;
}

.mat-mdc-paginator {
  //font-size: em(14px);
  margin-top: em(30px);
}

// Bottom Sheet
.mat-bottom-sheet-container {
  padding: em(40px) em(20px) !important;
  border-radius: 5px;
}

// Divider
.mat-divider {
  border-top-color: $bon-blue-light2 !important;
}

// Utility class

.bon-link {
  color: $bon-blue;

  &-white {
    color: $bon-white;
  }

  &-underline {
    text-decoration: underline;
  }

  mat-icon {
    vertical-align: bottom;
  }

  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}

.break-line {
  white-space: pre-wrap;
}

// Style to fix the weird border with angular material
.mdc-notched-outline__notch {
  border-right: none !important;
}

.mdc-list-item__primary-text {
  overflow: initial !important;
}

// delete strange glow on buttons
.mdc-list-item:focus::before {
  background-color: initial !important;
  opacity: initial !important;
}

.mat-mdc-button-persistent-ripple::before {
  background-color: initial !important;
}

// tables
.mat-sort-header-content {
  text-align: left !important;
}

//modals
@media screen and (min-width: 481px) {
  .mat-mdc-dialog-surface {
    min-width: fit-content !important;
  }
}

// date picker
.mat-datepicker-content .mat-calendar {
  margin-bottom: 20px;
}

// fix style for inputs with disables state - may looks like read-only
.mat-form-field-disabled .mdc-floating-label--float-above {
  color: $bon-gray-darker !important;
}

.mat-form-field-disabled .mdc-text-field__input {
  color: $bon-gray-darker !important;
}

.mat-form-field-disabled .mdc-notched-outline__leading,
.mat-form-field-disabled .mdc-notched-outline__notch,
.mat-form-field-disabled .mdc-notched-outline__trailing {
  border-color: $bon-gray !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: $bon-gray-darker !important;
}

mat-form-field:has(mat-date-range-input) {
  cursor: pointer;
}

mat-form-field:has(mat-date-range-input) mat-label,
mat-form-field:has(mat-date-range-input) label,
mat-form-field:has(mat-date-range-input) input,
mat-form-field:has(mat-date-range-input) mat-date-range-input,
mat-form-field:has(mat-datepicker-toggle) mat-datepicker-toggle {
  cursor: pointer;
}
