/* For use in src/lib/core/theming/_palette.scss */
$md-bon: (
  50 : #eaebfb,
  100 : #cbccf5,
  200 : #a8abef,
  300 : #8589e8,
  400 : #6b6ce2,
  500 : #5250db,
  600 : #4d47d0,
  700 : #443cc3,
  800 : #3d30b7,
  900 : #3017a4,
  A100 : #ffffff,
  A200 : #cfcfff,
  A400 : #9d9cff,
  A700 : #8482ff,
  contrast: (
    50 : #333333,
    100 : #333333,
    200 : #333333,
    300 : #333333,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #333333,
    A200 : #333333,
    A400 : #333333,
    A700 : #333333,
  )
);
/* For use in src/lib/core/theming/_palette.scss */
$md-bonwarn: (
  50 : #f7e0e2,
  100 : #ecb3b6,
  200 : #e08086,
  300 : #d34d55,
  400 : #c92630,
  500 : #c0000c,
  600 : #ba000a,
  700 : #b20008,
  800 : #aa0006,
  900 : #9c0003,
  A100 : #ffc7c7,
  A200 : #ff9494,
  A400 : #ff6161,
  A700 : #ff4747,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);



